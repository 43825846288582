@import "../../styles/variables.scss";

.zdBtn {
  background: $citrine;
  font-size: $fontSize_base;
  font-weight: $fontWeightBold;
  color: $black;
  padding: 1rem 1rem 0.7rem 1rem;
  border-radius: rem(50px);
  border: none;
  position: fixed;
  bottom: rem(105px);
  right: rem(15px);
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  .zdLabel {
    position: relative;
    top: rem(-2px);
    display: none;
  }
}

.chatCallout {
  background: $citrine;
  position: fixed;
  right: rem(80px);
  bottom: rem(111px);
  font-size: $fontSize_xs;
  padding: 0.5rem 1rem;
  border-radius: rem(30px);
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: rem(5px) 0 rem(5px) rem(20px);
    border-color: transparent transparent transparent #e6f200;
    position: absolute;
    right: rem(-15px);
    top: 50%;
    transform: translateY(-50%);
  }
}

.animatingOut {
  bottom: rem(-20px) !important;
  opacity: 0;
}

@include media-breakpoint-down(lg) {
  .zdNoCta {
    bottom: rem(20px);
  }
  .zdBubbleNoCta {
    bottom: rem(26px);
  }
}

@include media-breakpoint-up(lg) {
  .zdBtn {
    bottom: 7rem;
    right: rem(20px);
    padding: 1rem 1.5rem;
    &.zdNoCta {
      bottom: rem(25px);
    }
    .zdLabel {
      display: inline-block;
    }
    .zdIcon {
      margin-right: 0.5rem;
    }
    &.zdHome {
      bottom: rem(50px) !important;
    }
  }
  .chatCallout {
    display: none;
  }
}
