// Homepage colors
// red BG: #ff3047
// button/three cards (middle)/carousel pink BGs: #ff8cde
// three cars module BGs: #00405c (blue), #470038 (purple)
// testimonial carousel yellow BG: #e8f700 (yellow)

// How It Works colors
// blue BG: #00babf
// yellow text/BG: #e8f700

// Our Team colors
// hero BG/pink text: #ff8cde
// yellow text: #e8f700
// dark BG: #14171f

// Careers colors
// hero image/two images blue BG: #a6c6eb
// two images yellow BG: #ece799
// open positions BG: #e8f700

$enable-validation-icons: false;
$body-color: #470039;
$table-color: #15161e;
$table-cell-padding-y: 1rem;
$btn-border-radius: 40px;
$btn-border-radius-lg: 40px;
$theme-colors: (
  "primary": #ff8cdd,
  "secondary": #e6f200,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
) !default;

@import "~bootstrap/scss/bootstrap";

@import "./variables.scss";
@import "https://fonts.googleapis.com/css2?family=Material+Icons";
@import "~react-multi-carousel/lib/styles.css";

:root {
  --au-blue: #00415c;
  --au-green: #00c100;
  --au-pink: #ff8cdd;
  --au-dark-pink: #ff70d4;
  --au-yellow: #e6f200;
  --au-dark-yellow: #cbd600;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: DM Sans, sans-serif; //styleName: Desktop/Main Navigation Items;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-align: center;
  &.noScroll {
    overflow: hidden;
  }
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.async-hide {
  opacity: 1 !important;
}

.custom-container {
  margin: 0;
  padding: 0;
  max-width: 1440px;
  margin: auto;
}

@include media-breakpoint-down(xxl) {
  .container {
    max-width: none !important;
  }
}

.carousel-caption {
  text-align: left;
  max-width: 915px;
  margin: auto;
}

.cta-button {
  background-color: $citrine !important;
  color: black;
  border: 0;
  border-radius: 62px;
  width: 180px;
  padding: 0.35rem 1rem;
  font-weight: $fontWeightBold;
  font-size: $fontSize_lgx;
  letter-spacing: $letterSpacingTight;
  margin-left: 1rem;
}

.mobile-cta-button {
  background-color: $red !important;
  margin-top: 24px;
  color: white;
  width: 60%;
  min-width: 250px;
}

.dealer-cta-button {
  background-color: $citrine !important;
  color: black;
  border: 0;
  border-radius: 62px;
  font-weight: 800;
  width: auto;
  padding: 12px 50px;
}

.dealer-mobile-cta-button {
  background-color: $citrine !important;
  margin-top: 24px;
  color: black;
  width: 250px;
}

.light-button {
  background-color: #ff8cde;
  // border: 2px solid #ff8cde;
  color: #470038;
  padding: 16px 30px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.08em;
  // text-transform: uppercase;
}

.light-button:hover {
  background-color: #470038;
  color: white;
  // border: 2px solid #15161E;
}

.dark-button {
  background-color: #15161e;
  color: white;
  width: 140px;
  // border: 2px solid #15161E;
  padding: 16px 30px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.08em;
  // text-transform: uppercase;
}

.dark-button:hover {
  background-color: transparent;
  color: #15161e;
  // border: 2px solid #15161E;
}

.error-button {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 16px 30px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.error-button:hover {
  background-color: #ffffff;
  color: #15161e;
  border: 2px solid #15161e;
}

// Modal CSS
.modal-open {
  padding-right: 0 !important;
  width: 100%;
}

#modal-header {
  background-color: transparent !important;
  display: flex;
  justify-content: flex-end;
  border: none;
  padding-right: 5vw;
}

#bio-modal-header {
  background-color: transparent !important;
  display: flex;
  justify-content: flex-end;
  border: none;
}

.video-modal .modal-content {
  background-color: transparent;
  border: none;
}

.bio-modal .modal-content {
  width: 70vw;
  min-height: 650px;
  height: 60vw;
  max-height: 800px;
  padding: 32px;
  padding-right: 12px;
  border: none;
}

.modal-body {
  padding: 0;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.btn-close {
  color: #ffffff !important;
  background-color: none !important;
}

// Bootstrap accordion overrides

.accordion:not(.use-default) {
  border-top: 1px solid $purple;
  border-bottom: 1px solid $purple;
  .accordion-item {
    border: none;
    .accordion-header {
      border: none;
      span {
        margin-right: 1rem;
        font-size: 1.25rem;
        color: $purple;
      }
      .accordion-button {
        padding: 1rem 0;
        font-weight: 700;
        background: none;
        font-size: 1.1rem;
        &:focus,
        &:active {
          box-shadow: none;
          background: none;
          color: black;
        }
        &::after {
          background-color: $purple;
          border-radius: 50%;
          background-image: none;
        }
      }
    }
  }
}

// Headings

.main-heading {
  font-size: $mhFontSize;
  line-height: $mhLineHeight;
  font-weight: $mhFontWeight;
  color: $mhColor;
  letter-spacing: $mhLetterSpacing;
}

.headline_sm {
  font-size: $fontSize_4xl;
  font-weight: $fontWeightBold;
  letter-spacing: $letterSpacingTighest;
  line-height: $lineHeight_xs;
  color: $black;
  margin-bottom: 2rem;
  max-width: 700px;
  &.white {
    color: $white;
  }
}

.underline-heading {
  font-size: $uhFontSize;
  line-height: $uhFontSize;
  text-decoration: $uhTextDecoration;
  font-weight: $uhFontWeight;
  color: $purple;
  text-decoration-color: $pink;
}

.underline-content {
  font-size: 1.4rem;
  line-height: 1.75rem;
}

.bold-subhead {
  font-size: $bshFontSize;
  font-weight: $bshFontWeight;
}

// Buttons

// TODO - move to component level styles
.btn-purple {
  background-color: $btnPurpleBG;
  font-size: $btnPurpleFontSize;
  border: $btnPurpleBorder;
  border-radius: $btnPurpleBorderRadius;
  padding: $btnPurplePadding;
  font-weight: $btnPurpleFontWeight;
  color: $btnPurpleColor;
  transition: $transStandard;
  &:hover,
  &:active,
  &:focus-visible {
    outline: none;
    box-shadow: none;
    background-color: lighten(saturate($btnPurpleBG, 2%), 5%);
    color: $btnPurpleColor;
  }
}

// Hide zendesk chat button.
iframe#launcher {
  display: none;
}

@media (max-width: 767px) {
  .btn-purple {
    font-size: 1rem;
    line-height: 1;
    padding: 0.75rem 2rem;
  }
}

// Form Style overrides
fieldset {
  margin-bottom: $fieldsetMarginBottom;
  text-align: $fieldsetTextAlign;
  legend {
    color: $legendColor;
    font-size: $legendFontSize;
    font-weight: $legendFontWeight;
    margin-bottom: $legendMarginBottom;
    &.citrine {
      color: $citrine;
      font-size: $fieldsetCitrineFontSize;
      font-weight: $fieldsetCitrineFontWeight;
    }
  }
}
input {
  &:active,
  &:focus {
    outline: none !important;
  }
}
.form-group {
  label {
    font-weight: $formLabelFontWeight;
  }
  .form-control {
    border-top: $fcBorderTop;
    border-right: $fcBorderRight;
    border-bottom: $fcBorderBottom;
    border-left: $fcBorderLeft;
    border-radius: $fcBorderRadius;
    padding: $fcPadding;
    font-size: $fcFontSize;
    &:active,
    &:focus {
      box-shadow: $fcActiveBoxShadow;
      outline: $fcActiveOutline;
    }
    &.reverse {
      color: $fcrColor;
      border-bottom-color: $fcrBorderBottomColor;
      &:active,
      &:focus {
        box-shadow: $fcActiveBoxShadow;
        outline: $fcActiveOutline;
      }
    }
  }
}

.form-error {
  color: $pink;
  display: block;
  padding-top: 0.25rem;
  font-size: $fontSize_xs;
}

// CAROUSEL CSS
.carousel-indicators {
  .active {
    background-color: white;
  }
}

.carousel-indicators [data-bs-target] {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
}

.carousel-control-prev:hover {
  cursor: pointer !important;
}

.carousel-control-next:hover {
  cursor: pointer !important;
}

// Bingo VDP form
#vdpForm {
  .form-label {
    font-size: rem(11px);
    line-height: rem(13px);
    font-weight: $fontWeightMedium;
  }
  .form-control {
    font-size: rem(17px);
    font-weight: $fontWeightNormal;
    padding: 0.5rem 1.25rem;
    transition: all 0.25s ease;
    &:focus,
    &:active {
      box-shadow: none;
      border: 1px solid $pink;
    }
    &.is-invalid {
      border-color: #ced4da;
      &:focus,
      &:active {
        box-shadow: none;
        border: 1px solid $pink;
      }
    }
  }
  .invalid-feedback {
    color: #ff3148;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 10px;
    margin-top: 5px;
  }
}

// QR canvas styling
#qr_code {
  canvas {
    width: 85px;
    height: auto;
    position: relative;
    top: 3px;
  }
}

#qr_code_landing {
  // Sometimes af smartscript loads multiple qr codes
  canvas {
    display: none;
    width: 110px;
    background-color: $white;
    padding: 0.25rem;
    height: auto;
    // ensure only the first qr is shown
    &:first-child {
      display: block !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .light-button {
    margin-top: 32px;
    width: 100%;
    height: 65px;
    font-size: 18px;
  }

  .dark-button {
    width: 150px;
    height: 60px;
    font-size: 16px;
    letter-spacing: 0.08em;
    margin-bottom: 15px;
  }

  .bio-modal .modal-content {
    max-width: none;
    height: 900px;
  }
}

@media screen and (max-width: 432px) {
  .bio-modal .modal-content {
    height: 700px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 420px) {
  .light-button {
    font-size: 4vw;
  }
}

// Bootstrap media queries
@media (min-width: 768px) {
  .headline_sm {
    font-size: $fontSize_5xl;
  }
}

@media (min-width: 992px) {
  .headline_sm {
    font-size: 4.2rem;
  }
}

@media (min-width: 1200px) {
  .main-heading {
    font-size: $mhFontSize1200;
    line-height: $mhLineHeight1200;
    margin: 0 auto;
  }
}

// Bootstrap media queries
@media (min-width: 1700px) {
  .main-heading {
    max-width: 800px;
  }
}

.content_with_pink_links {
  a {
    color: $pink;
    text-decoration: underline;

    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }
}

.react-player-w-file > video {
  height: auto !important;
  max-height: 100%;
}

.scrollable-sibling {
  position: relative;
  z-index: 1;
}
