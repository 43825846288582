@import "../../styles/variables.scss";

.maintenance {
  background: $purple;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  .logo {
    margin-top: 2rem;
  }
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $white;
  }
}
